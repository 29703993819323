import { actionLists } from './popupsLogic.js';
import {
    MAX_INFO,
    NEGATIVE_MULTIPLIER,
    NEGATIVE_NUMBER,
} from './gameConfig.js';

export function calcTutorialResults(players, playersCount, round) {
    players.forEach((player) => {
        if (round === 1) player.irritation = 1;
        for (let pointType in player.currentRoundScore) {
            // console.log('pl', player.playerNumber, pointType, player.tutorial);
            if (pointType === 'irritation') {
                if (playersCount === player.playerNumber) {
                    players[0].currentRoundScore.irritation +=
                        player.tutorial[0].recon[pointType];
                    if (round === 1)
                        players[0].currentRoundScore[pointType] +=
                            player.tutorial[0].provocation[pointType];
                } else {
                    players[player.playerNumber].currentRoundScore[pointType] +=
                        player.tutorial[0].recon[pointType];
                    if (round === 1)
                        players[player.playerNumber].currentRoundScore[
                            pointType
                        ] += player.tutorial[0].provocation[pointType];
                }
            } else {
                player.currentRoundScore[pointType] +=
                    player.tutorial[0].recon[pointType];
                if (round === 1)
                    player.currentRoundScore[pointType] +=
                        player.tutorial[0].provocation[pointType];
            }
            // console.log('pl', player.playerNumber, player.currentRoundScore);
        }
        if (round === 2) {
            if (player.irritation > 0) {
                player.irritation--;
            }
            actionLists.observationStart.push(player.name);
            actionLists.rest.push(player.name);
        }
    });
}

export function playersCleanupTutorial(players, lang, round) {
    players.forEach((player) => {
        if (round === 1) {
            player.isAnalysing = true;
            player.analysisResults = [];
            const arr = ['recon', 'provocation'];
            arr.forEach((el) => {
                const effect = Object.assign(
                    player.tutorial[0][el].analysisEffect
                );
                player.analysisResults.push({
                    place: player.tutorial[0].placeName[lang],
                    operation: el,
                    effect,
                });
                player.tutorial[0][el].selected = false;
            });
            player.otherActions.analysis = false;
        } else if (round === 2) {
            actionLists.observationStart.push(player.name);
            player.otherActions.observation = false;
            player.otherActions.rest1 = false;
            player.otherActions.rest2 = false;
        }
        player.irritation += player.currentRoundScore.irritation;
        if (player.irritation === 3) {
            actionLists.lostOne.push(player.name);
            for (let pointType in player.informations) {
                player.informations[pointType]--;
            }
        }
        if (player.irritation >= 4) {
            player.irritation = 4;
            actionLists.lostTwo.push(player.name);
            for (let pointType in player.informations) {
                player.informations[pointType] -= 2;
            }
        }
        for (let pointType in player.currentRoundScore) {
            player.currentRoundScore[pointType] = 0;
        }
        for (let operation in player.tutorial[0]) {
            player.tutorial[0][operation].selected = false;
        }

        for (let action in player.otherActions) {
            player.otherActions[action] = false;
        }

        player.currentDecisions = null;
        player.decisionsCounter = 0;
    });
}

export function calcRoundResults(players, playersCount) {
    players.forEach((player) => {
        let isTrained = false;
        player.trainings.forEach((el) => {
            if (el.selected) {
                el.learned = true;
                if (!isTrained) actionLists.training.push(player.name);
                isTrained = true;
            }
        });
        player.places.forEach((place) => {
            for (let operation in place) {
                if (place[operation].selected) {
                    for (let pointType in player.currentRoundScore) {
                        if (pointType === 'irritation') {
                            if (playersCount === player.playerNumber) {
                                players[0].currentRoundScore.irritation +=
                                    place[operation][pointType];
                            } else {
                                players[player.playerNumber].currentRoundScore[
                                    pointType
                                ] += place[operation][pointType];
                            }
                        } else {
                            player.currentRoundScore[pointType] +=
                                place[operation][pointType];
                        }
                    }
                    const effectiveTraining = player.trainings.find(
                        (tr) => tr.name === place[operation].training.skill
                    );
                    if (effectiveTraining) {
                        if (effectiveTraining.learned) {
                            for (let pointType in player.currentRoundScore) {
                                if (
                                    place[operation].training.effect[pointType]
                                ) {
                                    player.currentRoundScore[pointType] +=
                                        place[operation].training.effect[
                                            pointType
                                        ];
                                }
                            }
                        }
                    }
                }
            }
        });
        let isRested = false;
        for (let action in player.otherActions) {
            if (
                player.otherActions[action] &&
                (action === 'rest1' || action === 'rest2')
            ) {
                if (player.irritation > 0) {
                    player.irritation--;
                }
                if (!isRested) actionLists.rest.push(player.name);
                isRested = true;
            }
            if (player.otherActions[action] && action === 'observation') {
                actionLists.observationStart.push(player.name);
            }
            if (player.otherActions[action] && action === 'analysis') {
                //analysis handled in roundSummary
            }
        }

        // console.log(player);
    });
}

export function playersCleanup(players, lang) {
    players.forEach((player) => {
        for (let action in player.otherActions) {
            // console.log('akcja', action, player.otherActions[action]);
            if (action === 'analysis' && player.otherActions.analysis) {
                player.isAnalysing = true;
                player.analysisResults = [];
                player.places.forEach((place) => {
                    for (let operation in place) {
                        if (place[operation].selected) {
                            const effect = Object.assign(
                                place[operation].analysisEffect
                            );
                            const effectiveTraining = player.trainings.find(
                                (tr) =>
                                    tr.name === place[operation].training.skill
                            );
                            if (effectiveTraining) {
                                if (effectiveTraining.learned) {
                                    for (let pointType in effect) {
                                        if (
                                            place[operation].training.effect[
                                                pointType
                                            ]
                                        ) {
                                            effect[pointType] +=
                                                place[
                                                    operation
                                                ].training.effect[pointType];
                                        }
                                    }
                                }
                            }
                            player.analysisResults.push({
                                place: place.placeName[lang],
                                operation,
                                effect,
                            });
                            place[operation].selected = false;
                        }
                    }
                });
            }
            if (action === 'observation' && player.otherActions.observation) {
                player.isObserving = true;
            }

            player.otherActions[action] = false;
        }

        for (let pointType in player.currentRoundScore) {
            if (pointType === 'irritation') {
                player.irritation += player.currentRoundScore[pointType];
            } else
                player.informations[pointType] +=
                    player.currentRoundScore[pointType];
            if (player.informations[pointType] < 0)
                player.informations[pointType] = 0;

            if (player.informations[pointType] > MAX_INFO)
                player.informations[pointType] = MAX_INFO;
            player.currentRoundScore[pointType] = 0;
        }
        player.places.forEach((place) => {
            for (let operation in place) {
                if (place[operation].selected)
                    place[operation].selected = false;
            }
        });
        player.trainings.forEach((training) => {
            training.selected = false;
        });
        if (player.irritation === 3) {
            actionLists.lostOne.push(player.name);
            for (let pointType in player.informations) {
                player.informations[pointType]--;
                if (player.informations[pointType] < 0)
                    player.informations[pointType] = 0;
            }
        }
        if (player.irritation >= 4) {
            player.irritation = 4;
            actionLists.lostTwo.push(player.name);
            for (let pointType in player.informations) {
                player.informations[pointType] -= 2;
                if (player.informations[pointType] < 0)
                    player.informations[pointType] = 0;
            }
        }

        player.currentDecisions = null;
        player.decisionsCounter = 0;
        // console.log(player);
    });
}

export function cleanTutorial(players) {
    players.forEach((player) => {
        player.isObserving = false;
        player.isAnalysing = false;
        for (let list in actionLists) {
            actionLists[list] = [];
        }
        player.irritation = 0;
        for (let pointType in player.informations) {
            player.informations[pointType] = NEGATIVE_NUMBER;
        }
        for (let pointType in player.currentRoundScore) {
            player.currentRoundScore[pointType] = 0;
        }
        player.currentDecisions = null;
        player.decisionsCounter = 0;

        for (let operation in player.tutorial[0]) {
            player.tutorial[0][operation].selected = false;
        }

        for (let action in player.otherActions) {
            player.otherActions[action] = false;
        }
    });
}

export function calculateFinalResults(players, playersCount) {
    players.forEach((player) => {
        player.finalScore = {
            ownBoard: 0,
            partnersBoard: 0,
            get totalScore() {
                return this.ownBoard + this.partnersBoard;
            },
        };
        for (let pointType in player.informations) {
            // const currScore = player.informations[pointType];
            const currScore = player.informations[pointType];

            if (currScore < NEGATIVE_NUMBER) {
                player.finalScore.ownBoard +=
                    currScore -
                    (NEGATIVE_NUMBER - currScore) * NEGATIVE_MULTIPLIER;
                // tu  stare
                // player.finalScore.ownBoard += currScore - 4;
                // koniec
                // console.log(
                //     pointType,
                //     '4 lub mniej informacji, punktow:',
                //     currScore
                // );
            } else {
                //tu stare
                //const currScoreAggegated = Math.floor((currScore - 4) / 6);
                // koniec
                // console.log(
                //     pointType,
                //     'informacji',
                //     currScore,
                //     'podzielone przez 6 (nie liczac pierwszych 4)',
                //     (currScore - 4) / 6,
                //     'zaokraglone',
                //     Math.floor((currScore - 4) / 6),
                //     'całość',
                //     currScoreAggegated
                // );
                player.finalScore.ownBoard += currScore;
            }
        }
    });
    players.forEach((player) => {
        if (player.playerNumber === 1) {
            player.finalScore.partnersBoard =
                players[playersCount - 1].finalScore.ownBoard;
        } else {
            player.finalScore.partnersBoard =
                players[player.playerNumber - 2].finalScore.ownBoard;
        }
    });
    players.forEach((player) => {
        // console.log(
        //     player.playerNumber,
        //     player.finalScore.ownBoard,
        //     player.finalScore.partnersBoard
        // );
    });
}
