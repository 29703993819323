<div class="footer">
    <div class="footer__logo-box">
        <img src="img/MindLab Games - logotyp.png" alt="MindLab Games Logo" />
    </div>
    <!-- <div>
        <input
            disabled="true"
            type="text"
            value={players[0].name + players[1].name}
        />
    </div> -->
    <!-- <div class="header__timer">
        <div class="timer__box">
            <p class="timer__text">{players[0].name}</p>
            <p class="timer__count">00:00:00</p>
        </div>
    </div> -->
    <div class="footer__logogame-box">
        <img src="img/informator.png" alt="Informator Logo" />
    </div>
</div>
