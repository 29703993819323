export function getGridClass(players) {
  switch (Number(players)) {
    case 3:
      return GRID_CLASSES[0];
    case 4:
      console.log(GRID_CLASSES[0]);
      return GRID_CLASSES[0];
    case 5:
      return GRID_CLASSES[1];
    case 6:
      return GRID_CLASSES[1];
    case 7:
      return GRID_CLASSES[2];
    case 8:
      return GRID_CLASSES[2];
  }
}

const GRID_CLASSES = [
  "main-grid main-grid--3-4players",
  "main-grid main-grid--5-6players",
  "main-grid main-grid--7-8players",
];

export const BACKGROUND_IMAGES = {
  regular: {
    decisions: "end_day",
    roundSummary: "new_day",
    gameSummary: "next",
    endResults: "new_day",
    next: "next",
  },
  last: {
    decisions: "end_day",
    roundSummary: "next",
    gameSummary: "next",
    endResults: "new_day",
    next: "next",
  },
};

export const LABELS = {
  pl: {
    title_header: "Informator",
    title_header2: "Aplikacja Trenera",
    raid: "Nalot",
    provocation: "Prowokacja",
    wiretapping: " Podsłuch",
    recon: "Rekonesans",
    training: "Trening",
    rest: "Odpoczynek",
    analysis: "Analiza",
    observation: "Obserwacja",
    results: "Wyniki",
    partners_points: "Punkty Twojego Partnera",
    your_points: "Twoje Punkty",
    total_points: "Twój Wynik",
    player: "Gracz:",
    decision_button_enter: "Wprowadź decyzje",
    decision_button_change: "Zmień&nbsp;decyzje",
    decision_button_close_analysis: "Zakończ&nbsp;analizę",
    decision_header: "Decyzje Gracza ",
    decision_button_accept: "Zaakceptuj",
    header_tutorial: "Tura przykładowa",
    header_round: "Runda ",
    header_round_results: "Wyniki Rundy",
    header_results_summary: "Podsumowanie",
    header_results_endgame: "Wyniki końcowe",
    timer_label: "Czas na podjęcie decyzji",
    analysis_same_as_board: "Zgodnie z planszą",
    round_summary_nameLine1_cobras: "Gang",
    round_summary_nameLine2_cobras: "Kobr",
    round_summary_nameLine1_sharks: "Gang",
    round_summary_nameLine2_sharks: "Rekinów",
    round_summary_nameLine1_wasps: "Gang",
    round_summary_nameLine2_wasps: "Os",
    round_summary_nameLine1_dragons: "Gang",
    round_summary_nameLine2_dragons: "Smoków",
    round_summary_nameLine1_irritation: "Irytacja",
    round_summary_nameLine2_irritation: "",
    analysis_results_no_operation: "Brak operacji",
    show_dices: "Pokaż&nbsp;kostki",
    wrong_tutorial_decisions: "Niewłaściwe decyzje w Tutorialu. Koryguję.",
    return_to_dialog: "Wróć",
  },
  eng: {
    title_header: "Informer",
    title_header2: "Trainers Companion App",
    raid: "Raid",
    provocation: "Provocation",
    wiretapping: "Wiretapping",
    recon: "Recon",
    training: "Training",
    rest: "Rest",
    analysis: "Insight",
    observation: "Observation",
    results: "Results",
    partners_points: "Your Partner's Points",
    your_points: "Your Points",
    total_points: "Total Score",
    player: "Player:",
    decision_button_enter: "Enter decisions",
    decision_button_change: "Change&nbsp;decisions",
    decision_header: "Players Decisions",
    decision_button_accept: "Accept",
    decision_button_close_analysis: "Close&nbsp;insight",
    header_tutorial: "Tutorial",
    header_round: "Turn ",
    header_round_results: "Turn Results",
    header_results_summary: "Summary",
    header_results_endgame: "Final Results",
    timer_label: "time for Decision",
    analysis_same_as_board: "Same as board",
    round_summary_nameLine1_cobras: "Cobras",
    round_summary_nameLine2_cobras: "",
    round_summary_nameLine1_sharks: "Sharks",
    round_summary_nameLine2_sharks: "",
    round_summary_nameLine1_wasps: "Wasps",
    round_summary_nameLine2_wasps: "",
    round_summary_nameLine1_dragons: "Dragons",
    round_summary_nameLine2_dragons: "",
    round_summary_nameLine1_irritation: "Irritation",
    round_summary_nameLine2_irritation: "",
    analysis_results_no_operation: "No operation",
    show_dices: "Show&nbsp;dice",
    wrong_tutorial_decisions:
      "Invalid decisions for Tutorial selected. Correcting.",
    return_to_dialog: "Return",
  },
};

export const messages = {
  pl: {
    trained: {
      header: "TRENING!",
      message:
        "Zrobił w tej turze TRENING. Zaznacza markerem odpowiednie pole na swojej planszy.",
      class: "popup__content--smaller",
    },
    rested: {
      header: "ODPOCZYNEK!",
      message:
        "Zrobił w tej turze ODPOCZYNEK. Za każdy odpoczynek zdejmuje 1 ZNACZNIK IRYTACJI i wrzuca go do pojemnika.",
      class: "popup__content--smaller",
    },
    observed: {
      header: "OBSERWACJA!",
      message:
        "Pobiera zagrane w tej turze KARTY OPERACJI od swojego Partnera po prawej stronie.",
      class: "popup__content--smaller",
    },
    observationEnded: {
      header: "OBSERWACJA!",
      message:
        "Oddaje przeczytane KARTY OPERACJI swojemu partnerowi po Prawej stronie.",
      class: "popup__content--smaller",
    },
    askDices: {
      header: "STOP!",
      message:
        "Czy każdy Gracz pobrał odpowiednie KOSTKI INFORMACJI i ZNACZNIKI IRYTACJI?",
      class: "popup__content--announcement",
    },
    returnActions: {
      header: "UWAGA!",
      message:
        "Wszyscy Gracze przekładają swoje PUNKTY AKCJI z powrotem do puli.",
      class: "popup__content--announcement",
    },
    countResults: {
      header: "STOP!",
      message: "Trenerze, podlicz wyniki graczy na tablicy.",
      class: "popup__content--announcement",
    },
    lostOne: {
      header: "IRYTACJA!",
      message:
        "Traci jedną informację na temat każdego gangu i wrzuca do pojemnika.",
      class: "popup__content--smaller",
    },
    lostTwo: {
      header: "IRYTACJA!",
      message:
        "Traci dwie informacje na temat każdego gangu i wrzuca do pojemnika.",
      class: "popup__content--smaller",
    },
    wrongTutorial: {
      header: "UWAGA!",
      message: "Niewłaściwe decyzje w Tutorialu. Koryguję.",
      class: "popup__content--announcement",
    },
    notEveryOneReady: {
      header: "UWAGA!",
      message: "Nie wszyscy gracze podjęli decyzje!",
      class: "popup__content--announcement",
    },
    endGame: {
      header: "UWAGA!",
      message: "Koniec gry.",
      class: "popup__content--announcement",
    },
    trainerSummary: {
      header: "STOP!",
      message: "Trenerze, podlicz wyniki graczy na tablicy.",
      class: "popup__content--announcement",
    },
    incompleteInput: {
      header: "STOP!",
      message:
        "Nie wszystkie decyzje zostały wprowadzone, czy na pewno chcesz zamknąć to okno?",
      class: "popup__content--announcement",
    },
    tutorialEnd: {
      header: "STOP!",
      message:
        "TO JEST KONIEC TUTORIALU, ZA CHWILĘ ROZPOCZNIE SIĘ GRA WŁAŚCIWA! ",
      class: "popup__content--announcement",
    },
  },
  eng: {
    trained: {
      header: "TRAINING!",
      message:
        "Decided to TRAIN in this turn. Player marks a corresponding box on his chart.",
      class: "popup__content--smaller",
    },
    rested: {
      header: "REST!",
      message:
        "Decided to REST in this turn. Remove one IRRITATION dice per every REST used and put it into the container.",
      class: "popup__content--smaller",
    },
    observed: {
      header: "OBSERVATION!",
      message:
        "Retrieves OPERATION CARDS that were played this turn from his Partner on his right side.",
      class: "popup__content--smaller",
    },
    observationEnded: {
      header: "OBSERVATION!",
      message: "Gives back the OPERATION CARDS to his Partner on his right.",
      class: "popup__content--smaller",
    },
    askDices: {
      header: "STOP!",
      message: "Has every Player received DICE in the right colors?",
      class: "popup__content--announcement",
    },
    returnActions: {
      header: "ATTENTION!",
      message: "All Players put their COUNTERS back in the pool.",
      class: "popup__content--announcement",
    },
    countResults: {
      header: "STOP!",
      message: "Coach, sum up the PLAYERS SCORES on the board.",
      class: "popup__content--announcement",
    },
    lostOne: {
      header: "IRRITATION!",
      message:
        "Lost 1 PIECE OF INFORMATION about every gang! Remove it from the board and put into the container!",
      class: "popup__content--smaller",
    },
    lostTwo: {
      header: "IRRITATION!",
      message:
        "Lost 2 PIECES OF INFORMATIONS about every gang! Remove it from the board and put into the container!",
      class: "popup__content--smaller",
    },
    wrongTutorial: {
      header: "WARNING!",
      message: "Invalid decisions for Tutorial selected. Correcting.",
      class: "popup__content--announcement",
    },
    notEveryOneReady: {
      header: "STOP!",
      message: "Not every player made his decisions.",
      class: "popup__content--announcement",
    },
    endGame: {
      header: "Warning",
      message: "Game over :)",
      class: "popup__content--announcement",
    },
    trainerSummary: {
      header: "STOP!",
      message: "Coach, sum up the PLAYER SCORES on the board.",
      class: "popup__content--announcement",
    },
    incompleteInput: {
      header: "STOP!",
      message:
        "There are some decisions left to input, are you sure to close this dialog? ",
      class: "popup__content--announcement",
    },
    tutorialEnd: {
      header: "STOP!",
      message: "Tutorial ends here, full game is about to begin. ",
      class: "popup__content--announcement",
    },
  },
};
