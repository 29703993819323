<script>
    export let player;
    import DecisionsInput from './DecisionsInput.svelte';
    import DecisionsInputTutorial from './DecisionsInputTutorial.svelte';
    import PlayerDecisions from './PlayerDecisions.svelte';
    import PlayerAnalysis from './PlayerAnalysis.svelte';
    import PlayerRoundSummary from './PlayerRoundSummary.svelte';
    import PlayerGameEndSummary from './PlayerGameEndSummary.svelte';
    import { LABELS } from './layout.js';
    let isNameDisabled = false;
    let isSummaryVisible = false;
    export let mode = '';
    export let lang = '';
    export let phase;
    export let round = 0;
    export let isInputVisible = false;

    // function toggleEditName() {
    //     console.log('editing');
    //     isNameDisabled = !isNameDisabled;
    // }
    $: if (player.currentDecisions) {
        console.log(
            player.playerNumber,
            typeof player.currentDecisions,
            player.isAnalysing
        );
    } else
        console.log(
            'false',
            player.currentDecisions,
            typeof player.currentDecisions
        );
</script>

{#if isInputVisible}
    {#if mode === 'tutorial'}
        <DecisionsInputTutorial
            {lang}
            {mode}
            {round}
            bind:isInputVisible
            bind:player
        />
    {:else}
        <DecisionsInput {lang} {mode} bind:isInputVisible bind:player />
    {/if}
{/if}
<div class="player-box">
    {#if phase === 'decisions'}
        {#if isSummaryVisible}
            <PlayerRoundSummary {player} {phase} {lang} />
        {:else if !player.currentDecisions}
            {#if !player.isAnalysing}
                <img
                    class="player-box__img"
                    src="img/{player.playerNumber}.png"
                    alt="agent-{player.playerNumber}-badge"
                />
                <div class="player-box__name">
                    <form action="" class="form__name">
                        <textarea
                            bind:value={player.name}
                            maxlength="25"
                            
                            spellcheck="false"
                            class="name-input heading-secondary"
                            id="player{player.playerNumber}_name"
                            disabled={isNameDisabled}
                        />
                        <!-- <i class="form__name-icon" on:click={toggleEditName}>
                            <img src="img/Icon material-mode-edit.png" alt="" />
                        </i> -->
                    </form>
                </div>
            {:else}
                <PlayerAnalysis {player} {lang} />
            {/if}
        {:else}
            <PlayerDecisions {player} />
        {/if}
    {:else if phase === 'roundSummary' || phase === 'gameSummary'}
        <PlayerRoundSummary {player} {phase} {lang} />
    {:else if phase === 'endResults'}
        <PlayerGameEndSummary {player} {phase} {lang} />
    {/if}
    {#if phase === 'decisions'}
        <button
            on:click={() => {
                isInputVisible = true;
            }}
            class:u-hidden={isSummaryVisible}
            class={!player.currentDecisions && !player.isAnalysing
                ? 'btn btn--decision'
                : 'btn btn--change'}
            ><span class="btn__text"
                >{#if !player.currentDecisions && !player.isAnalysing}{@html LABELS[
                        lang
                    ]
                        .decision_button_enter}{:else if player.isAnalysing}{@html LABELS[
                        lang
                    ].decision_button_close_analysis}{:else}
                    {@html LABELS[lang].decision_button_change}{/if}
            </span></button
        >
        <button
            on:click={() => {
                isSummaryVisible = !isSummaryVisible;
                console.log('summ', isSummaryVisible);
            }}
            class="btn btn--dice-toggler"
        >
            <img src="../img/irytacja.png" alt />
            <span>?</span>
        </button>
    {/if}
</div>
